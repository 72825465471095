















































































































































































































































































































































































































































































































































































import { debounce, debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ApproveInvoice,
  GenerateReceipt,
  ResponseDetailSummaryInvoiceAR,
} from "@/models/interface/invoice.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { contactServices } from "@/services/contact.service";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import printJs from "print-js";
import { Component, Mixins } from "vue-property-decorator";

interface OptionModel {
  id: string;
  name: string;
  value?: string;
}
@Component
export default class Invoices extends Mixins(MNotificationVue) {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formModalDetail!: WrappedFormUtils;
  form!: WrappedFormUtils;
  formPosting!: WrappedFormUtils;
  formPrint!: WrappedFormUtils;
  loading = {
    branch: false,
    source: false,
    customerName: false,
    invoiceNumber: false,
    invoiceStatus: false,
    find: false,
    posting: false,
    print: false,
    approve: false,
    customer: false,
    printReceipt: false,
  };
  modal = {
    posting: false,
    detail: false,
    print: false,
  };
  page = 1;
  limit = 10;
  dataTable = {
    print: [] as any[],
    dataSource: [] as any[],
    totalData: 0,
    detail: [] as ResponseDetailSummaryInvoiceAR[],
  };
  selectRow = {
    header: [] as number[],
    modal: [] as number[],
  };
  dataBranch = [] as OptionModel[];
  dataListSource = [] as OptionModel[];
  dataCustomer = [] as OptionModel[];
  dataInvoiceNumber = [] as OptionModel[];
  dataInvoiceStatus = [] as OptionModel[];
  tempData = {
    listIdInvoice: [] as string[],
    checkDatePosting: "",
  };
  columnsTable = [
    {
      title: this.$t("lbl_source"),
      dataIndex: "invoiceSource",
      key: "invoiceSource",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 150,
      scopedSlots: { customRender: "clickColumn" },
    },
    {
      title: this.$t("lbl_fp_no"),
      dataIndex: "taxInvoiceNumber",
      key: "fpNo",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_cust_code"),
      dataIndex: "custCode",
      key: "custCode",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "dpp",
      key: "dpp",
      width: 150,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_vat"),
      dataIndex: "vat",
      key: "vat",
      width: 150,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_total_amount"),
      dataIndex: "grandTotal",
      key: "grandTotal",
      width: 150,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNo",
      key: "journalNumber",
      width: 150,
      scopedSlots: { customRender: "clickColumn" },
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ];
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "lbl_branch",
      placeholder: "lbl_branch",
      decorator: ["branch"],
    },
    source: {
      label: "lbl_source",
      name: "lbl_source",
      placeholder: "lbl_source",
      decorator: ["source"],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "lbl_customer_name",
      placeholder: "lbl_customer_name",
      decorator: ["customerName"],
    },
    invoiceDateFrom: {
      label: "lbl_invoice_date_from",
      name: "lbl_invoice_date_from",
      placeholder: "lbl_invoice_date_from",
      decorator: ["invoiceDateFrom"],
    },
    invoiceDateTo: {
      label: "lbl_invoice_date_to",
      name: "lbl_invoice_date_to",
      placeholder: "lbl_invoice_date_to",
      decorator: ["invoiceDateTo"],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "lbl_invoice_number",
      placeholder: "lbl_invoice_number",
      decorator: ["invoiceNumber"],
    },
    invoiceStatus: {
      label: "lbl_invoice_status",
      name: "lbl_invoice_status",
      placeholder: "lbl_invoice_status",
      decorator: ["invoiceStatus"],
    },
    invoiceReceivedDate: {
      label: "lbl_invoice_received_date",
      name: "lbl_invoice_received_date",
      placeholder: "lbl_invoice_received_date",
      decorator: [
        "invoiceReceivedDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "lbl_invoice_date",
      placeholder: "lbl_invoice_date",
      decorator: ["invoiceDate"],
    },
    taxType: {
      label: "lbl_tax_type",
      name: "lbl_tax_type",
      placeholder: "lbl_tax_type",
      decorator: ["taxType"],
    },
    top: {
      label: "lbl_term_of_payment",
      name: "lbl_term_of_payment",
      placeholder: "lbl_term_of_payment",
      decorator: ["top"],
    },
    currency: {
      label: "lbl_currency",
      name: "lbl_currency",
      placeholder: "lbl_currency",
      decorator: ["currency"],
    },
    poNumber: {
      label: "lbl_purchase_order_number",
      name: "lbl_purchase_order_number",
      placeholder: "lbl_purchase_order_number",
      decorator: ["poNumber"],
    },
    referenceNumber: {
      label: "lbl_reference_number",
      name: "lbl_reference_number",
      placeholder: "lbl_reference_number",
      decorator: ["referenceNumber"],
    },
    invoiceTaxNo: {
      label: "lbl_invoice_tax_no",
      name: "lbl_invoice_tax_no",
      placeholder: "lbl_invoice_tax_no",
      decorator: ["invoiceTaxNo"],
    },
    shipTo: {
      label: "lbl_ship_to",
      name: "lbl_ship_to",
      placeholder: "lbl_ship_to",
      decorator: ["shipTo"],
    },
    usageType: {
      label: "lbl_usage_type",
      name: "lbl_usage_type",
      placeholder: "lbl_usage_type",
      decorator: ["usageType"],
    },
    description: {
      label: "lbl_description",
      name: "lbl_description",
      placeholder: "lbl_description",
      decorator: ["description"],
    },
    subTotal: {
      label: "lbl_sub_total",
      name: "lbl_sub_total",
      placeholder: "lbl_sub_total",
      decorator: ["subTotal"],
    },
    discount: {
      label: "lbl_discount",
      name: "lbl_discount",
      placeholder: "lbl_discount",
      decorator: ["discount"],
    },
    baseAmount: {
      label: "lbl_base_amount",
      name: "lbl_base_amount",
      placeholder: "lbl_base_amount",
      decorator: ["taxBase"],
    },
    vat: {
      label: "lbl_vat",
      name: "lbl_vat",
      placeholder: "lbl_vat",
      decorator: ["ppn"],
    },
    total: {
      label: "lbl_total",
      name: "lbl_total",
      placeholder: "lbl_total",
      decorator: ["grandTotal"],
    },
    picCustomerName: {
      label: "lbl_pic_customer_name",
      name: "lbl_pic_customer_name",
      placeholder: "lbl_pic_customer_name",
      decorator: [
        "picCustomerName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    billToAddress: {
      label: "lbl_bill_to_address",
      name: "lbl_bill_to_address",
      placeholder: "lbl_bill_to_address",
      decorator: ["billToAddress"],
    },
  };
  columnsTablePrint = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 30,
      scopedSlots: { customRender: "isNull" },
      responsiveColTextArea: [
        {
          name: "documentDescription",
          placeholder: "Documents",
          style: "width: 100%;",
        },
      ],
      responsiveColInput: [
        {
          name: "totalQty",
          placeholder: "QTY",
          style: "width: 100%;",
        },
        {
          name: "unit",
          placeholder: "UOM",
          style: "width: 100%;",
        },
        {
          name: "notes",
          placeholder: "Notes",
          style: "width: 100%;",
        },
      ],
    },
    {
      title: "Documents",
      dataIndex: "documentDescription",
      key: "documents",
      width: 150,
      scopedSlots: { customRender: "documentDescription" },
    },
    {
      title: "QTY",
      dataIndex: "totalQty",
      key: "qty",
      width: 50,
      scopedSlots: { customRender: "totalQty" },
    },
    {
      title: "UOM",
      dataIndex: "unit",
      key: "unit",
      width: 50,
      scopedSlots: { customRender: "unit" },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: 50,
      scopedSlots: { customRender: "notes" },
    },
  ] as ColumnTableCustom;
  columnsTableDetail = [
    {
      title: this.$t("lbl_unit"),
      dataIndex: "unit",
      key: "unit",
      width: 25,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      width: 75,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "subtotal",
      key: "subtotal",
      width: 75,
      scopedSlots: { customRender: "isCurrency" },
    },
  ] as ColumnTableCustom;
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "invoiceHeader" });
    this.formModalDetail = this.$form.createForm(this, {
      name: "invoiceDetail",
    });
    this.formPosting = this.$form.createForm(this, { name: "invoicePosting" });
    this.formPrint = this.$form.createForm(this, { name: "invoicePrint" });
  }

  mounted(): void {
    this.onSearchInvoiceNo = debounceProcess(this.onSearchInvoiceNo);

    this.initOption();
  }

  initOption() {
    this.getBranch("");
    this.getCustomerName("");
    this.getInvoiceNumber("");
    this.getSource("");
    this.getInvoiceStatus("");
  }
  cancelModal(type: string) {
    this.modal[type] = false;
  }
  handlePrint() {
    if (this.listIdInvoice.length) {
      const valueCustomer = this.listIdInvoice.map(item => {
        return item.customerName;
      });
      const status = this.listIdInvoice.map(item => {
        return item.status;
      });
      const id = this.listIdInvoice.map(item => {
        return item.id;
      });
      const sameDataCustomer = valueCustomer.every(
        val => val === valueCustomer[0]
      );
      const sameDataStatus = status.every(
        v => v != "Rejected" && v != "Cancelled" && v != "Returned"
      );
      if (sameDataCustomer && sameDataStatus) {
        this.loading.print = true;
        invoiceServices
          .generateReceipt(id.toString())
          .then(res => {
            this.modal.print = true;
            res.details.forEach((item, index) => {
              item["no"] = index + 1 + ".";
              item["key"] = index;
            });
            this.dataTable.print = res.details;
            this.formPrint.getFieldDecorator("customerName", {
              initialValue: res.customerName,
            });
            this.formPrint.getFieldDecorator("billToAddress", {
              initialValue: res.billToAddress,
            });
            this.formPrint.getFieldDecorator("branch", {
              initialValue: res.branchName,
            });
          })
          .finally(() => (this.loading.print = false));
      } else
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("lbl_validate_print").toString(),
        });
    } else {
      this.$notification.error({
        message: this.$t("lbl_error_title").toString(),
        description: this.$t("lbl_modal_delete_error_description").toString(),
      });
    }
  }
  clickButtonTable(type: string) {
    switch (type) {
      case "find":
        this.handleFind(false);
        break;
      case "print":
        this.handlePrint();
        break;
      case "posting":
        this.handlePosting();
        break;
      case "create":
        this.$router.push(`/accountreceivables/invoices/create`);
        break;
      default:
        break;
    }
  }
  handlePrintReceipt() {
    this.formPrint.validateFields((err, value) => {
      if (!err) {
        const params = {
          params:
            this.$store.state.authStore.authData.companyName ||
            "PT. SATRIA PIRANTI PERKASA",
        } as RequestQueryParamsModel;
        const dataObj = this.dataTable.print.map(item => {
          return {
            documentDescription: item.documentDescription,
            id: item.id ? item.id : null,
            notes: item.notes,
            totalQty: item.totalQty,
            unit: item.unit,
          };
        });
        const payload = {
          branchName: value.branch,
          customerName: value.customerName,
          billToAddress: value.billToAddress,
          details: dataObj as [],
        } as GenerateReceipt;
        this.loading.printReceipt = true;
        invoiceServices
          .printReceipt(payload, params)
          .then(dataBlob => {
            if (dataBlob) {
              const url = window.URL.createObjectURL(new Blob([dataBlob]));
              printJs({
                printable: url,
                type: "pdf",
                targetStyles: "*",
                showModal: true,
                modalMessage: "Loading ...",
              });
              this.modal.print = false;
            }
          })
          .finally(() => {
            this.loading.printReceipt = false;
          });
      }
    });
  }
  handlePosting() {
    const dateList = [] as any;
    if (this.listIdInvoice.length) {
      const valueCustomer = this.listIdInvoice.map(item => {
        return item.customerName;
      });
      const status = this.listIdInvoice.map(item => {
        return item.status;
      });
      this.listIdInvoice.forEach((item, idx, array) => {
        dateList.push({
          calculate: moment().diff(item.date, "days"),
          date: item.date,
        });
        if (idx === array.length - 1) {
          dateList.sort((a, b) => a.calculate - b.calculate);
          debounce(() => {
            this.checkDatePosting = dateList[0].date;
          }, 200);
        }
      });

      this.formPosting.resetFields();
      const sameDataCustomer = valueCustomer.every(
        val => val === valueCustomer[0]
      );
      const sameDataStatus = status.every(
        v => v === "Delivered" || v === "Need Approval"
      );
      if (sameDataCustomer && sameDataStatus) {
        this.modal.posting = true;
      } else {
        if (status.includes("Unpaid")) {
          this.$notification.error({
            description: "Invoice has been posted",
            message: "Error",
            duration: 30,
          });
        } else {
          this.$notification.error({
            description:
              "Customer must be same and status must be Delivered Or Need Approval",
            message: "Error",
            duration: 30,
          });
        }
      }
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to print",
      });
    }
  }

  async fetchInvoiceSummary(invoiceId: string): Promise<void> {
    try {
      const res = await invoiceServices.getInvoiceDetailSummary(invoiceId);
      this.modal.detail = true;
      const data = {
        invoiceNumber: res.invoiceNo,
        invoiceDate: res.invoiceDate,
        taxType: res.taxType,
        top: res.top,
        currency: res.currency,
        poNumber: res.poNo,
        referenceNumber: res.reference,
        invoiceTaxNo: res.taxInvoiceNumber,
        customerName: res.customerName,
        shipTo: res.customerBillToAddress,
        usageType: res.usageType,
        description: res.description,
        subTotal: currencyFormat(res.subTotal),
        discount: currencyFormat(res.discount),
        taxBase: currencyFormat(res.taxBase),
        ppn: currencyFormat(res.ppn),
        grandTotal: currencyFormat(res.grandTotal),
      };
      this.setDataForm(data);
      this.dataTable.detail = res.invoiceArLinePrintDTOList;
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  handleClickColumn(record, objColumnNameValue: { column; value }): void {
    if (objColumnNameValue.column === "journalNo") {
      this.$router.push("/generaljournal/journal/detail/" + record.journalId);
    } else {
      this.fetchInvoiceSummary(record.id);
    }
  }

  setDataForm(values): void {
    for (const key in values) {
      this.formModalDetail.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formModalDetail.setFieldsValue(values);
  }
  responseViewTable(response) {
    this.$router.push({
      name: "accountreceivables.invoices.detail",
      params: { id: response.data.id },
    });
  }
  onSelectChange(value) {
    this.selectRow.header = value;
    this.listIdInvoice = [];
    value.forEach(element => {
      this.listIdInvoice.push({
        id: this.dataTable.dataSource[element].id,
        customerName: this.dataTable.dataSource[element].customerName,
        status: this.dataTable.dataSource[element].status,
        date: this.dataTable.dataSource[element].invoiceDate,
      });
    });
  }

  getBranch(valueSearch: string) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loadingBranch = false));
  }
  addRow() {
    this.dataTable.print = [
      ...this.dataTable.print,
      {
        key: this.dataTable.print.length,
        documentDescription: "",
        notes: "",
        totalQty: "",
        no: this.dataTable.print.length + 1 + ".",
        unit: "",
      },
    ];
  }
  handleInput(value: string, key: number, objectColInput) {
    this.dataTable.print[key][objectColInput.name] = value;
    this.dataTable.print = this.dataTable.print.slice();
  }
  showConfirmation() {
    if (this.selectRow.modal.length) {
      this.$confirm({
        title: this.$t("lbl_modal_delete_title_confirm"),
        content: this.$t("lbl_modal_delete_info", {
          count: this.selectRow.modal.length,
        }),
        onOk: () => {
          this.dataTable.print = this.dataTable.print.filter((data, _index) => {
            return !this.selectRow.modal.includes(data.key);
          });
          this.dataTable.print.forEach((data, index) => (data.key = index));

          this.dataListItems = this.dataTable.print.slice();
          this.selectRow.modal = [];
          this.dataTable.print.forEach((item, index) => {
            item.no = index + 1 + ".";
          });
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: this.$t("lbl_error_title").toString(),
        description: this.$t("lbl_modal_delete_error_description").toString(),
      });
    }
  }
  onSelectChangeModal(selectedRowKeys) {
    this.selectRow.modal = selectedRowKeys;
  }
  approve() {
    this.formPosting.validateFields((err, value) => {
      if (!err) {
        const id = this.listIdInvoice.map(item => {
          return item.id;
        });
        const payload = [
          {
            invoiceReceivedDate: moment(value.invoiceReceivedDate).format(),
            picCustomerName: value.picCustomerName,
          },
        ] as ApproveInvoice[];
        this.loading.approve = true;
        invoiceServices
          .approveInvoice(payload, id.toString())
          .then(_res => {
            this.modal.posting = false;
            this.selectRow.header = [];
            this.handleFind(false);
          })
          .finally(() => (this.loading.approve = false));
      }
    });
  }

  getSource(valueSearch: string): void {
    const params = new RequestQueryParams();
    params.name = "INVOICE_AR_SOURCE";
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loadingSource = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataListSource = response;
      })
      .finally(() => (this.loadingSource = false));
  }

  getCustomerName(valueSearch: string): void {
    const params = new RequestQueryParams("customer~true_AND_active~true");
    if (valueSearch) {
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    }
    this.loading.customer = true;
    contactServices
      .listContactData(params)
      .then(
        response =>
          (this.dataCustomer = response.data.map(item => {
            return {
              id: item.id || "",
              name: item.firstName || "",
            };
          }))
      )
      .finally(() => (this.loading.customer = false));
  }

  getInvoiceNumber(valueSearch: string): void {
    const params = new RequestQueryParams();
    if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
    this.loadingInvoiceNumber = true;
    invoiceServices
      .getListInvoiceAR(params)
      .then(response => {
        this.dataInvoiceNumber = response.data.map(item => {
          return {
            id: item.id || "",
            name: item.documentNumber || "",
          };
        });
      })
      .finally(() => (this.loadingInvoiceNumber = false));
  }

  onSearchInvoiceNo(value = ""): void {
    this.getInvoiceNumber(value);
  }

  getInvoiceStatus(valueSearch: string): void {
    const params = new RequestQueryParams();
    params.name = "INVOICE_AR_STATUS";
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loadingInvoiceStatus = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataInvoiceStatus = response;
      })
      .finally(() => (this.loadingInvoiceStatus = false));
  }
  changeToCurrencyFormat(val): string {
    if (val === 0) {
      return "0";
    } else {
      return currencyFormat(val);
    }
  }
  assignSearch(key, value, and): string {
    if (key === "branch" && value) return and + `branchId~*${value}*`;
    else if (key === "source" && value) return and + `invoiceSource~*${value}*`;
    else if (key === "customerName" && value)
      return and + `customerId~*${value}*`;
    else if (key === "invoiceDateFrom" && value)
      return (
        and +
        `invoiceDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "invoiceDateTo" && value)
      return (
        and +
        `invoiceDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "invoiceNumber" && value)
      return and + `secureId~*${value}*`;
    else if (key === "invoiceStatus" && value) return and + `status~*${value}*`;
    else return "";
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  disabledDate(current) {
    return (
      current < moment(this.checkDatePosting, DEFAULT_DATE_FORMAT) ||
      current > moment()
    );
  }
  handleFind(page: boolean): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        limit: this.limit,
        page: page ? this.page - 1 : 0,
        sorts: "createdDate:desc",
      };
      params.search = this.dynamicSearch(res);
      this.loading.find = true;
      invoiceServices
        .getListInvoiceARV2(params)
        .then(response => {
          this.dataTable.totalData = response.totalElements;
          this.dataTable.dataSource = response.data.map((dataMap, index) => {
            return {
              ...dataMap,
              key: index,
              invoiceDate: moment(dataMap.invoiceDate).format(
                DEFAULT_DATE_FORMAT
              ),
            };
          });
        })
        .finally(() => (this.loading.find = false));
    });
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.handleFind(true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.handleFind(true);
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
